<template>
  <div class="home-page content-page">
    <div class="search-box flex al-i-c ju-c-c">
      <div class="search-item flex al-i-c m-r-40">
        <span class="search-title">展厅名称</span>
        <div class="input-box">
          <input class="input" v-model="searchHallName" placeholder="请输入展厅名称" type="text">
        </div>
      </div>
      <div class="search-item flex al-i-c m-r-40">
        <span class="search-title">展厅位置</span>
        <div class="input-box">
          <input class="input" v-model="searchHallAddress" placeholder="请输入展厅位置" type="text">
        </div>
      </div>
      <img class="search-btn" @click="getData" src="../assets/images/search-btn.png" alt="">
    </div>
    <div class="chunk-list flex" :class="list.length>2?'ju-c-sb':''">
      <div class="chunk-item m-b-20" :class="{'m-r-20':list.length<3}" v-for="(item,index) in list" :key="index">
        <div class="title-box flex al-i-c ju-c-sb">
          <div class="title-text">{{item.hallName}}</div>
          <div class="title-line"></div>
        </div>
        <div class="data-box flex al-i-c ju-c-sb m-b-35">
          <div class="left-box info-box">
            <div class="data-item flex al-i-c">
              <div class="small-chunk"></div>
              <div class="data-detail flex al-i-c">
                <div class="title">展厅位置</div>
                <div class="value">
                  {{item.hallAddress}}
                </div>
              </div>
            </div>
            <div class="data-item flex al-i-c">
              <div class="small-chunk"></div>
              <div class="data-detail flex al-i-c">
                <div class="title">主题</div>
                <div class="value">
                  {{item.themesName}}
                </div>
              </div>
            </div>
            <div class="data-item flex al-i-c">
              <div class="small-chunk"></div>
              <div class="data-detail flex al-i-c">
                <div class="title">累计客流</div>
                <div class="value">
                  {{item.guestsCount}}
                </div>
              </div>
            </div>
            <div class="data-item flex al-i-c">
              <div class="small-chunk"></div>
              <div class="data-detail flex al-i-c">
                <div class="title">设备标识</div>
                <div class="value">
                  {{item.clientId}}
                </div>
              </div>
            </div>
          </div>
          <div class="right-box file-data-box">
            <Tooltip max-width="200" placement="left">
              <div slot="content" v-html="item.fileCount"></div>
              <img class="icon-img m-r-20" src="../assets/images/icon-zywj.png" alt="">
            </Tooltip>
          </div>
        </div>

        <!-- <div class="data-box m-b-35 flex">
          <div class="right-box flex al-i-c ju-c-sa">
            <div class="file-item">
              <img class="icon-img" src="../assets/images/icon-video.png" alt="">
              <div class="title">{{item.video_num}}个视频文件</div>
            </div>
            <div class="file-item">
              <img class="icon-img" src="../assets/images/icon-ppt.png" alt="">
              <div class="title">{{item.ppt_num}}个PPT文件</div>
            </div>
            <div class="file-item">
              <img class="icon-img" src="../assets/images/icon-excel.png" alt="">
              <div class="title">{{item.excel_num}}个excel文件</div>
            </div>
          </div>
        </div> -->

        <div class="data-box flex al-i-c ju-c-sa">
          <div class="left-box flex al-i-c">
            <div class="line-status on-line flex al-i-c ju-c-c m-r-20">
              <div class="text-c">
                <div class="num">{{item.onlineCount}}</div>
                <div class="title">在线</div>
              </div>
            </div>
            <div class="line-status off-line flex al-i-c ju-c-c">
              <div class="text-c">
                <div class="num">{{item.offCount}}</div>
                <div class="title">离线</div>
              </div>
            </div>
          </div>
          <div class="btn-box right-box flex al-i-c ju-c-sb">
            <div class="btn" @click="remoteControl(item)">远程控制</div>
            <div class="btn" @click="lookDetail(item)">查看详情</div>
            <div class="btn" @click="realTimePlaying(item)">实时播放</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="getData" show-elevator />
    </div>
  </div>
</template>

<script>
import { homeInitDataApi } from '@/apis/indexApis'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      total: 0,
      currPage: 1,
      pageSize: 6,
      list: [],
      searchHallName: '',
      searchHallAddress: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        let res = await homeInitDataApi({
          rows: this.pageSize,
          currentPage: this.currPage,
          hallName: this.searchHallName,
          hallAddress: this.searchHallAddress,
        })
        console.log('res:', res)
        this.list = res.data
        this.total = res.count
      } catch (error) {
        console.log(error)
      }
    },
    //查看详情
    lookDetail(item) {
      this.$router.push({
        name: 'dataAnalyse',
        params: {
          id: item.rowId,
          hallName: item.hallName,
        },
      })
    },
    //远程控制
    remoteControl(item) {
      console.log(item)
      this.$router.push({
        name: 'remoteControl',
        params: {
          id: item.rowId,
          hallName: item.hallName,
        },
      })
    },
    // 实时播放
    realTimePlaying(item) {
      this.$router.push({
        name: 'realTimePlaying',
        params: {
          id: item.rowId,
          hallName: item.hallName,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.home-page {
  .search-box {
    margin: 34px 0;

    .search-btn {
      width: 110px;
      height: 50px;
      display: block;
    }

    .search-item {
      .search-title {
        font-size: 16px;
        color: #65a6d5;
        margin-right: 15px;
      }

      .input-box {
        padding: 0 20px;
        width: 400px;
        height: 50px;
        background-image: url('../assets/images/search-input-bg.png');
        background-size: 400px 50px;
        background-repeat: no-repeat;

        .input {
          display: block;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: 0;
          outline: none;
          color: #23aeca;
          font-size: 16px;

          &::placeholder {
            color: #233a67;
            font-size: 16px;
          }
        }
      }
    }
  }

  .chunk-list {
    margin: 0 37px;
    flex-wrap: wrap;

    .chunk-item {
      width: 600px;
      height: 320px;
      padding: 20px;
      position: relative;
      background-image: url('../assets/images/home-chunk-bg.png');
      background-size: 600px 320px;
      background-repeat: no-repeat;

      .title-box {
        margin-bottom: 28px;

        .title-text {
          font-size: 22px;
          color: #fff;
        }

        .title-line {
          width: 402px;
          height: 14px;
          background-image: url('../assets/images/title-line.png');
          background-size: 402px 14px;
          background-repeat: no-repeat;
        }
      }

      .data-box {
        .data-item {
          min-width: 140px;
          margin-bottom: 5px;

          .small-chunk {
            width: 8px;
            height: 8px;
            background-color: #1ef2f3;
            margin-right: 10px;
          }

          .data-detail {
            font-size: 16px;
            width: calc(100% - 20px);

            .title {
              color: #1ef2f3;
              line-height: 16px;
              width: 60px;
              text-align: justify;
              text-align-last: justify;
              margin-right: 10px;
              font-size: 14px;
            }

            .value {
              width: 80%;
              font-size: 14px;
              color: #fff;
              @include multiEllipsis(1);
            }
          }
        }

        .left-box {
          &.info-box {
            flex: 1;
          }
          .line-status {
            width: 60px;
            height: 80px;
            border-radius: 10px;
            border: 1px dotted #1ef2f3;

            &.on-line {
              background-color: #08263d;
              border-color: #1ef2f3;
              color: #1ef2f3;
            }

            &.off-line {
              background-color: #3a0706;
              border-color: #f7625e;
              color: #f7625e;
            }

            .num {
              font-size: 24px;
              font-weight: bold;
            }

            .title {
              font-size: 14px;
            }
          }
        }

        .right-box {
          margin: 0 25px;
          &.file-data-box {
            /deep/.ivu-tooltip {
              .ivu-tooltip-arrow {
                border-left-color: rgba($color: #1942ae, $alpha: 0.7);
              }
              .ivu-tooltip-inner {
                background-color: rgba($color: #1942ae, $alpha: 0.7);
              }
            }
          }
          .file-item {
            .icon-img {
              display: block;
              width: 36px;
              height: 44px;
              margin: 0 auto 10px;
            }

            .title {
              color: #1ef2f3;
              font-size: 14px;
              @include multiEllipsis(1);
            }
          }
        }
      }

      .btn-box {
        flex: 1;
        .btn {
          display: block;
          width: 110px;
          height: 40px;
          line-height: 40px;
          background-image: url('../assets/images/home-btn-bg.png');
          background-size: 110px 40px;
          background-repeat: no-repeat;
          text-align: center;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .page-box {
    margin-right: 37px;
    margin-bottom: 37px;
  }
}
</style>
