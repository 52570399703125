import axios from '@/libs/api.request'
import { getToken } from '@/libs/util'

// 获取用户列表
export const getUserDataApi = (params) => {
  return axios.request({
    url: 'admin/list.do',
    params,
    method: 'get',
  })
}

// 新增用户
export const addUserInfoApi = (data) => {
  return axios.request({
    url: 'admin/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改用户
export const editUserInfoApi = (data) => {
  return axios.request({
    url: 'admin/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除用户
export const deleteUserInfoApi = (params) => {
  return axios.request({
    url: 'admin/delete.do',
    params,
    method: 'get',
    showMsg: true,
  })
}

// 获取角色列表
export const getRoleDataApi = (params) => {
  return axios.request({
    url: 'role/list.do',
    params,
    method: 'get',
  })
}

// 新增角色
export const addRoleInfoApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'role/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改角色
export const editRoleInfoApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'role/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除角色
export const deleteRoleInfoApi = (params) => {
  return axios.request({
    url: 'role/delete.do',
    params,
    method: 'get',
    showMsg: true,
  })
}

// 获取菜单树
export const getPermissionDataApi = (params) => {
  return axios.request({
    url: 'permission/tree.do',
    params,
    method: 'get',
  })
}

// 创建菜单
export const addPermissionInfoApi = (data) => {
  return axios.request({
    url: 'permission/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改菜单
export const editPermissionInfoApi = (data) => {
  return axios.request({
    url: 'permission/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除菜单
export const deletePermissionInfoApi = (params) => {
  return axios.request({
    url: 'permission/delete.do',
    params,
    method: 'get',
    showMsg: true,
  })
}

// 获取系统日志
export const getBizLogDataApi = (params) => {
  return axios.request({
    url: 'bizLog/list.do',
    params,
    method: 'get',
  })
}
