<template>
  <div class="content-page exhibition-option-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="hallName">
            <Input type="text" v-model="searchModel.hallName" placeholder="请输入查询展庁名称"></Input>
          </FormItem>
          <FormItem prop="optionName">
            <Input type="text" v-model="searchModel.optionName" placeholder="请输入查询展项名称"></Input>
          </FormItem>
          <FormItem prop="optionType">
            <Select v-model="searchModel.optionType" clearable placeholder="请选择查询展项类型">
              <!-- <Option v-for="item in optionTypes" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
              <Option v-for="(val, key, index) in optionTypes" :value="key" :key="index">{{ val }}</Option>
            </Select>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="optionType">
          <div>{{optionTypes[row.optionType]}}</div>
        </template>
        <template slot-scope="{ row }" slot="playMethod">
          <div>{{playMethods[row.playMethod]}}</div>
        </template>
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line-text'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已失效' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
    <my-drawer-com :title="(editFormModel.hasOwnProperty('rowId')?'编辑':'新增')+'展项'" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editFormModel" :model="editFormModel" :rules="editFormModelRule" :label-width="$px2spx(100)" label-position="left">
            <FormItem label="展项名称" prop="optionName">
              <Input v-model="editFormModel.optionName" placeholder="请输入展项名称"></Input>
            </FormItem>
            <FormItem label="展项类型" prop="optionType">
              <Select v-model="editFormModel.optionType" placeholder="请选择展项类型">
                <Option v-for="(val, key, index) in optionTypes" :value="key" :key="index">{{ val }}</Option>
              </Select>
            </FormItem>
            <FormItem label="所属展厅" v-if="hallInfoList.length!==0" prop="hallId">
              <Select v-model="editFormModel.hallId" :label-in-value="true" @on-change="hallChange" placeholder="请选择所属展厅" transfer transfer-class-name="my-select">
                <Option v-for="(item,index) in hallInfoList" :value="item.rowId" :key="index">{{ item.hallName }}</Option>
              </Select>
            </FormItem>
            <FormItem label="设备标识" prop="deviceNum">
              <Input v-model="editFormModel.deviceNum" placeholder="请输入设备标识"></Input>
            </FormItem>
            <FormItem label="设备IP" prop="ipAds">
              <Input v-model="editFormModel.ipAds" placeholder="请输入设备IP"></Input>
            </FormItem>
            <FormItem v-if="editFormModel.optionType==='ctl'" label="播放方式" prop="playMethod">
              <Select v-model="editFormModel.playMethod" placeholder="请选择播放方式">
                <Option v-for="(val, key, index) in playMethods" :value="key" :key="index">{{ val }}</Option>
              </Select>
            </FormItem>
            <FormItem v-if="editFormModel.optionType==='ctl'&&editFormModel.playMethod!=='welcome'" label="播放文件" prop="playFileList">
              <Select v-model="editFormModel.playFileList" multiple placeholder="请选择播放文件" transfer transfer-class-name="my-select">
                <Option v-for="(item,index) in fileList" :value="item.rowId" :key="index">{{ item.fileName }}</Option>
              </Select>
            </FormItem>
            <FormItem label="欢迎词" v-if="editFormModel.playMethod==='welcome'" prop="greetWord">
              <Input v-model="editFormModel.greetWord" placeholder="请输入欢迎词"></Input>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="editFormModel.memo" placeholder="请输入展项备注"></Input>
            </FormItem>
            <FormItem label="状态">
              <i-switch :true-value="1" :false-value="0" v-model="editFormModel.activeFlag" />
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
import {
  hallOptionDataApi,
  addHallOptionApi,
  editHallOptionApi,
  deleteHallOptionApi,
} from '@/apis/exhibitionManagementApis'
import { getFileDataApi } from '@/apis/fileManageApis'
import { hallInfoDataApi } from '@/apis/exhibitionManagementApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
export default {
  name: 'ExhibitionOption',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    // const validateOrgIds = (rule, value, callback) => {
    //   if (this.editFormModel.userType === 'hall') {
    //     if (typeof value === 'number') {
    //       callback()
    //     } else {
    //       callback(new Error('请选择展厅'))
    //     }
    //   } else if (this.editFormModel.userType === 'area') {
    //     if (Array.isArray(value) && value.length > 0) {
    //       callback()
    //     } else {
    //       callback(new Error('请选择展厅'))
    //     }
    //   } else {
    //     console.log(123123)
    //   }
    // }

    return {
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '名称',
          key: 'optionName',
          align: 'center',
        },
        {
          title: '展项类型',
          slot: 'optionType',
          align: 'center',
        },
        {
          title: '设备标识',
          key: 'deviceNum',
          align: 'center',
        },
        {
          title: '欢迎词',
          key: 'greetWord',
          align: 'center',
        },
        {
          title: '所属展厅',
          key: 'hallName',
          align: 'center',
        },
        {
          title: '播放方式',
          slot: 'playMethod',
          align: 'center',
        },
        {
          title: '实时状态',
          key: 'realStatus',
          align: 'center',
        },
        {
          title: '更新时间',
          key: 'realTime',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      optionTypes: {
        show: '仅展示',
        ctl: '可控制',
      },
      playMethods: {
        welcome: '欢迎词',
        auto: '自动播放',
        manual: '手动播放',
      },
      pageSize: 10,
      currPage: 1,
      total: 0,
      isSubmiting: false,
      editFormModel: {
        activeFlag: 1,
      },
      selectItems: [],
      searchModel: {},
      hallInfoList: [],
      fileList: [],
      editFormModelRule: {
        optionName: [
          {
            required: true,
            message: '请输入展项名称',
            trigger: 'blur',
          },
        ],
        optionType: [
          {
            required: true,
            message: '请选择展项类型',
            trigger: 'change',
          },
        ],
        hallId: [
          {
            type: 'number',
            required: true,
            message: '请选择所属展厅',
            trigger: 'change',
          },
        ],
        deviceNum: [
          {
            required: true,
            message: '请输入设备标识',
            trigger: 'blur',
          },
        ],
        ipAds: [
          {
            required: true,
            message: '请输入设备IP',
            trigger: 'blur',
          },
        ],
        playMethod: [
          {
            required: true,
            message: '请选择播放方式',
            trigger: 'change',
          },
        ],
        playFileList: [
          {
            type: 'array',
            required: true,
            message: '请选择播放文件',
            trigger: 'change',
          },
        ],
        greetWord: [
          {
            required: true,
            message: '请输入欢迎词',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  async mounted() {
    this.getData()
    let hallInfoRes = await hallInfoDataApi()
    console.log(hallInfoRes)
    this.hallInfoList = hallInfoRes.data
    let fileListRes = await getFileDataApi()
    console.log(hallInfoRes)
    this.fileList = fileListRes.data
  },
  methods: {
    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      console.log('search:', search)
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await hallOptionDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
    // 新增行
    addRow() {
      this.editFormModel = {
        activeFlag: 1,
      }
      this.$refs.myDrawerCom.show()
    },
    // 编辑行
    editRow(row) {
      console.log(row)
      this.editFormModel = deepCopyObj(row)
      this.$refs.myDrawerCom.show()
    },
    // 删除行
    async trashRow(row, vm) {
      console.log(row)
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteHallOptionApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 提交新增/修改
    submitData() {
      this.$refs['editFormModel'].validate(async (valid) => {
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              if (this.editFormModel.hasOwnProperty('rowId')) {
                //修改
                let editResult = await editHallOptionApi(this.editFormModel)
                console.log('editResult:', editResult)
              } else {
                //新增
                let addResult = await addHallOptionApi(this.editFormModel)
                console.log('addResult:', addResult)
              }
              this.$refs.myDrawerCom.hide()
              this.editFormModel = {
                activeFlag: 1,
              }
              this.getData()
              this.isSubmiting = false
            } catch (error) {
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },
    hallChange(hall) {
      console.log(hall)
      this.editFormModel.hallName = hall.label
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
