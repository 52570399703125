<template>
  <div class="content-page file-manage-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="fileName">
            <Input type="text" v-model="searchModel.fileName" placeholder="请输入查询文件名称"></Input>
          </FormItem>
          <FormItem prop="fileType">
            <Select v-model="searchModel.fileType" clearable placeholder="请选择查询文件类型">
              <Option v-for="(item,index) in fileTypes" :value="item" :key="index">{{ item }}</Option>
            </Select>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line-text'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已失效' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
    <my-drawer-com :title="(editFormModel.hasOwnProperty('rowId')?'编辑':'新增')+'文件'" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editFormModel" :model="editFormModel" :rules="editFormModelRule" :label-width="$px2spx(100)" label-position="left">
            <FormItem label="上传文件" prop="fileUrl">
              <Upload class="my-upload" type="drag" accept=".pdf,image/*,audio/*,video/*" :on-remove="fileRemove" :on-success="fileUploadSuccess" :headers="uploadFileHeaders" :action="uploadFileApi">
                <div style="padding: 20px 0">
                  <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                  <p>点击或拖动文件到此处进行上传</p>
                </div>
              </Upload>
            </FormItem>
            <FormItem label="文件名称" prop="fileName">
              <Input v-model="editFormModel.fileName" placeholder="请输入文件名称"></Input>
            </FormItem>
            <FormItem label="文件类型" prop="fileType">
              <Select v-model="editFormModel.fileType" placeholder="请选择文件类型">
                <Option v-for="(item,index) in fileTypes" :value="item" :key="index">{{ item }}</Option>
              </Select>
            </FormItem>
            <FormItem label="所属展厅" prop="hallId">
              <Select v-model="editFormModel.hallId" :label-in-value="true" @on-change="hallChange" placeholder="请选择所属展厅" transfer transfer-class-name="my-select">
                <Option v-for="(item,index) in hallInfoList" :value="item.rowId" :key="index">{{ item.hallName }}</Option>
              </Select>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="editFormModel.memo" type="tel" placeholder="请输入文件备注"></Input>
            </FormItem>
            <FormItem label="状态">
              <i-switch :true-value="1" :false-value="0" v-model="editFormModel.activeFlag" />
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
import { getToken } from '@/libs/util'
import {
  getFileDataApi,
  addFileApi,
  editFileApi,
  deleteFileApi,
  uploadFileApi,
} from '@/apis/fileManageApis'
import { hallInfoDataApi } from '@/apis/exhibitionManagementApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
export default {
  name: 'FileManagement',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    const validateFileUrl = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请上传文件'))
      } else {
        callback()
      }
    }
    return {
      uploadFileApi,
      uploadFileHeaders: {
        tokenId: getToken(),
      },
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '文件名称',
          key: 'fileName',
          align: 'center',
        },
        {
          title: '文件大小',
          key: 'fileSize',
          align: 'center',
        },
        {
          title: '文件类型',
          key: 'fileType',
          align: 'center',
        },
        {
          title: '文件地址',
          key: 'fileUrl',
          align: 'center',
        },
        {
          title: '所属展厅',
          key: 'hallName',
          align: 'center',
        },
        {
          title: '备注',
          key: 'memo',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      fileTypes: ['PDF', 'IMG', 'VIDEO', 'PPT', 'WORD', 'XLS'],
      hallInfoList: [],
      pageSize: 10,
      currPage: 1,
      total: 0,
      isSubmiting: false,
      editFormModel: {
        activeFlag: 1,
        fileName: '',
        fileSize: '',
        fileUrl: '',
      },
      editFormModelRule: {
        fileUrl: [
          {
            required: true,
            validator: validateFileUrl,
            trigger: 'change',
          },
        ],
        fileName: [
          {
            required: true,
            message: '请输入文件名称',
            trigger: 'blur',
          },
        ],
        fileType: [
          {
            required: true,
            message: '请选择文件类型',
            trigger: 'change',
          },
        ],
        hallId: [
          {
            type: 'number',
            required: true,
            message: '请选择主题',
            trigger: 'change',
          },
        ],
      },
      selectItems: [],
      searchModel: {},
    }
  },
  async mounted() {
    this.getData()
    let hallInfoRes = await hallInfoDataApi()
    console.log(hallInfoRes)
    this.hallInfoList = hallInfoRes.data
  },
  methods: {
    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      console.log('search:', search)
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await getFileDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
    // 新增行
    addRow() {
      this.editFormModel = {
        activeFlag: 1,
        fileName: '',
        fileSize: '',
        fileUrl: '',
      }
      this.$refs.myDrawerCom.show()
    },
    // 编辑行
    editRow(row) {
      console.log(row)
      this.editFormModel = deepCopyObj(row)
      this.$refs.myDrawerCom.show()
    },
    // 删除行
    async trashRow(row, vm) {
      console.log(row)
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteFileApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 提交新增/修改
    submitData() {
      this.$refs['editFormModel'].validate(async (valid) => {
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              if (this.editFormModel.hasOwnProperty('rowId')) {
                //修改
                let editResult = await editFileApi(this.editFormModel)
                console.log('editResult:', editResult)
              } else {
                //新增
                let addResult = await addFileApi(this.editFormModel)
                console.log('addResult:', addResult)
              }
              this.$refs.myDrawerCom.hide()
              this.editFormModel = {
                activeFlag: 1,
                fileName: '',
                fileSize: '',
                fileUrl: '',
              }
              this.getData()
              this.isSubmiting = false
            } catch (error) {
              console.log(error)
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },
    hallChange(hall) {
      console.log(hall)
      this.editFormModel.hallName = hall.label
    },
    // 文件上传成功
    fileUploadSuccess(res, file) {
      console.log(res, file)
      this.editFormModel.fileName = file.name
      this.editFormModel.fileSize = res.data.size
      this.editFormModel.fileUrl = res.data.url
    },
    fileRemove() {
      this.editFormModel.fileName = ''
      this.editFormModel.fileSize = ''
      this.editFormModel.fileUrl = ''
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
