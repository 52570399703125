<template>
  <div class="content-page exhibition-management-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="hallName">
            <Input type="text" v-model="searchModel.hallName" placeholder="请输入查询展厅名称"></Input>
          </FormItem>
          <FormItem prop="themesId">
            <Select v-model="searchModel.themesId" clearable placeholder="请选择查询展厅主题">
              <Option v-for="item in themesList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line-text'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已失效' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
    <my-drawer-com :title="(editFormModel.hasOwnProperty('rowId')?'编辑':'新增')+'展厅'" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editFormModel" :model="editFormModel" :rules="editFormModelRule" :label-width="$px2spx(100)" label-position="left">
            <FormItem label="名称" prop="hallName">
              <Input v-model="editFormModel.hallName" placeholder="请输入展厅名称"></Input>
            </FormItem>
            <FormItem label="展厅说明" prop="hallInfo">
              <Upload multiple :action="uploadFileApi"  accept=".pdf,image/*,audio/*,video/*" :on-remove="fileRemove" :on-success="fileUploadSuccess"
                      :default-file-list="lstFile">
                <Button icon="ios-cloud-upload-outline"  type="primary">上传文件</Button>
              </Upload>
            </FormItem>
            <FormItem label="省市区" prop="hallCityValue">
              <Cascader :data="cityData" v-model="selectValue" @on-change="selectCity"></Cascader>
            </FormItem>
            <FormItem label="地址" prop="hallAddress">
              <Input v-model="editFormModel.hallAddress" placeholder="请输入展厅地址"></Input>
            </FormItem>
            <FormItem label="登录名" prop="userName">
              <Input v-model="editFormModel.userName" placeholder="请输入展厅登录名"></Input>
            </FormItem>
            <FormItem label="密码" prop="password">
              <Input v-model="editFormModel.password" placeholder="请输入展厅密码"></Input>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="editFormModel.memo" type="tel" placeholder="请输入展厅备注"></Input>
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
  import  cityJson  from '@/libs/pcas-code.json'
  import {
  hallInfoDataApi,
  addHallInfoApi,
  editHallInfoApi,
  deleteHallInfoApi,
} from '@/apis/exhibitionManagementApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
  import {
    uploadFileApi,
  } from '@/apis/fileManageApis'
export default {
  name: 'ExhibitionManagement',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    return {
      uploadFileApi: uploadFileApi,
      cityData: cityJson,
      selectValue: "",
      lstFile: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '名称',
          key: 'hallName',
          align: 'center',
        },
        {
          title: '省市区',
          key: 'hallCity',
          align: 'center',
        },
        {
          title: '地址',
          key: 'hallAddress',
          align: 'center',
        },
        {
          title: '登录名',
          key: 'userName',
          align: 'center',
        },
        {
          title: '密码',
          key: 'password',
          align: 'center',
        },
        {
          title: '当前主题',
          key: 'themesName',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '备注',
          key: 'memo',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      themesList: [],
      pageSize: 10,
      currPage: 1,
      total: 0,
      isSubmiting: false,
      editFormModel: {
        activeFlag: 1,
      },
      editFormModelRule: {
        hallName: [
          {
            required: true,
            message: '请输入展厅名称',
            trigger: 'blur',
          },
        ],
        hallAddress: [
          {
            required: true,
            message: '请输入展厅详细地址',
            trigger: 'blur',
          },
        ],
        userName: [
          {
            required: true,
            message: '请输入登录名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          },
        ],
        hallInfo: [
          {
            required: true,
            message: '请上传文件',
            trigger: 'blur',
          },
        ],
        hallCityValue: [
          {
            required: true,
            message: '请选择城市',
            trigger: 'blur',
          },
        ],
        // themesId: [
        //   {
        //     type: 'number',
        //     required: true,
        //     message: '请选择主题',
        //     trigger: 'change',
        //   },
        // ],
      },
      selectItems: [],
      searchModel: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await hallInfoDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
    // 新增行
    addRow() {
      this.editFormModel = {
        activeFlag: 1,
      }
      this.$refs.myDrawerCom.show()
    },
    // 编辑行
    editRow(row) {
      this.selectValue = ""
      this.lstFile = []
      this.editFormModel = deepCopyObj(row)
      if(this.editFormModel.hallCityValue){
        this.selectValue = this.editFormModel.hallCityValue.split(",")
      }
      this.$refs.myDrawerCom.show()
      //显示文件
      if(this.editFormModel.hallInfo){
        for(let item of this.editFormModel.hallInfo.split(";")) {
          if (item) {
            let obj = {}
            obj.url = item
            obj.name = item.substring(item.lastIndexOf("/") + 1)
            this.lstFile.push(obj)
         }
        }
      }
    },
    // 删除行
    async trashRow(row, vm) {
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteHallInfoApi({
        ids: ids,
      })
    },
    // 提交新增/修改
    submitData() {
      console.info(this.editFormModel.hallInfo)
      console.info(this.editFormModel.hallCityValue)
      console.info(this.editFormModel.hallCity)
      this.$refs['editFormModel'].validate(async (valid) => {
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              if (this.editFormModel.hasOwnProperty('rowId')) {
                //修改
                let editResult = await editHallInfoApi(this.editFormModel)
              } else {
                //新增
                let addResult = await addHallInfoApi(this.editFormModel)
              }
              this.$refs.myDrawerCom.hide()
              this.editFormModel = {
                activeFlag: 1,
              }
              this.getData()
              this.isSubmiting = false
            } catch (error) {
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },
    // 文件上传成功
    fileUploadSuccess(res, file) {
      let obj = {}
      obj.url=res.data.url
      obj.name=res.data.url.substring(res.data.url.lastIndexOf("/")+1)
      this.lstFile.push(obj)
      this.editFormModel.hallInfo += res.data.url+";"
    },
    fileRemove() {
      this.lstFile = []
      this.editFormModel.hallInfo  = ''
    },
    selectCity(value,opts){
      this.editFormModel.hallCity = ""
      for(let item of opts){
        this.editFormModel.hallCity += item.label
      }
      //数组转str
      this.editFormModel.hallCityValue = value.toString()
    }
  },
}
</script>

<style lang="scss" scoped>
  .ivu-upload-list-file{
    color: white;
  }
</style>
