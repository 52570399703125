import { render, staticRenderFns } from "./trash-affirm-com.vue?vue&type=template&id=1eba8756&"
import script from "./trash-affirm-com.vue?vue&type=script&lang=js&"
export * from "./trash-affirm-com.vue?vue&type=script&lang=js&"
import style0 from "./trash-affirm-com.vue?vue&type=style&index=0&id=1eba8756&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports