<template>
  <div class="content-page role-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="roleName">
            <Input type="text" v-model="searchModel.roleName" placeholder="请输入查询角色名称"></Input>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line-text'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已失效' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
    <my-drawer-com :title="(editFormModel.hasOwnProperty('rowId')?'编辑':'新增')+'角色'" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editFormModel" :model="editFormModel" :rules="editFormModelRule" :label-width="60">
            <FormItem label="名称" prop="roleName">
              <Input v-model="editFormModel.roleName" placeholder="请输入角色名称"></Input>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="editFormModel.memo" type="tel" placeholder="请输入角色备注"></Input>
            </FormItem>
            <FormItem label="菜单">
              <Tree ref="menuTree" :data="menuTreeData" show-checkbox multiple></Tree>
            </FormItem>
            <FormItem label="状态">
              <i-switch :true-value="1" :false-value="0" v-model="editFormModel.activeFlag" />
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
import {
  getPermissionDataApi,
  getRoleDataApi,
  addRoleInfoApi,
  editRoleInfoApi,
  deleteRoleInfoApi,
} from '@/apis/systemApis'
import { deepCopyObj, addItemProperty } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
export default {
  name: 'Role',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    return {
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '角色名称',
          key: 'roleName',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '备注',
          key: 'memo',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      themesList: [
        {
          value: 0,
          label: 'Longfor',
        },
        {
          value: 1,
          label: 'Longfor2',
        },
        {
          value: 2,
          label: 'Longfor3',
        },
      ],
      menuTreeData: [],
      menuTreeDataCopy: [],
      pageSize: 10,
      currPage: 1,
      total: 0,
      isSubmiting: false,
      editFormModel: {
        activeFlag: 0,
      },
      editFormModelRule: {
        roleName: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
        ],
      },
      selectItems: [],
      searchModel: {},
    }
  },
  async mounted() {
    await this.getData()
    try {
      let menuResult = await getPermissionDataApi()
      this.menuTreeData = addItemProperty({
        list: menuResult.data,
        propertyText: 'checked',
        propertyVal: false,
        bubble: true,
        bubbleText: 'children',
      })
      this.menuTreeDataCopy = deepCopyObj(this.menuTreeData)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      console.log('search:', search)
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await getRoleDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
    checkedMenuTree(list, permission) {
      list.forEach((item) => {
        if (permission.indexOf(item.rowId) !== -1) {
          item.checked = true
        }
        if (item.hasOwnProperty('children') && item.children.length > 0) {
          this.checkedMenuTree(item.children, permission)
        }
      })
    },
    // 新增行
    addRow() {
      this.menuTreeData = deepCopyObj(this.menuTreeDataCopy)
      this.editFormModel = {
        activeFlag: 1,
      }
      this.$refs.myDrawerCom.show()
    },
    // 编辑行
    async editRow(row) {
      console.log(row)
      this.editFormModel = deepCopyObj(row)
      this.checkedMenuTree(this.menuTreeData, row.permission)
      console.log(this.menuTreeData)
      this.$refs.myDrawerCom.show()
    },
    // 删除行
    async trashRow(row, vm) {
      console.log(row)
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteRoleInfoApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 提交新增/修改
    submitData() {
      this.$refs['editFormModel'].validate(async (valid) => {
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              let postData = {
                role: {
                  rowId: this.editFormModel.rowId,
                  roleName: this.editFormModel.roleName,
                  memo: this.editFormModel.memo,
                },
                permission: this.$refs.menuTree
                  .getCheckedAndIndeterminateNodes()
                  .map((item) => {
                    return item.rowId
                  }),
                activeFlag: this.editFormModel.activeFlag,
              }

              console.log(postData)

              if (this.editFormModel.hasOwnProperty('rowId')) {
                //修改
                postData.rowId = this.editFormModel.rowId
                let editResult = await editRoleInfoApi(postData)
                console.log('editResult:', editResult)
              } else {
                //新增
                let addResult = await addRoleInfoApi(postData)
                console.log('addResult:', addResult)
              }
              this.$refs.myDrawerCom.hide()
              this.editFormModel = {
                activeFlag: 1,
              }
              this.getData()
              this.isSubmiting = false
            } catch (error) {
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
