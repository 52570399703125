import axios from '@/libs/api.request'
import { getToken } from '@/libs/util'

// 获取播放推流地址和暂停推流
export const getStreamApi = (params) => {
  return axios.request({
    url: 'hallCtl/stream.do',
    params,
    method: 'get',
  })
}

// 远程控制设置待机文件
export const setAwaitFileApi = (params) => {
  return axios.request({
    url: 'hallCtl/setAwaitFile.do',
    params,
    method: 'get',
  })
}
// 远程控制设置播放文件
export const setPlayFileApi = (params) => {
  return axios.request({
    url: 'hallCtl/setPlayFile.do',
    params,
    method: 'get',
  })
}

//远程控制，获取展厅设备数据
export const remoteControlDeviceDataApi = (data) => {
  return axios.request({
    url: 'remote_control_device_data',
    data,
    method: 'get',
  })
}
//远程控制，获取多媒体控制播放清单数据
export const remoteControlMediaDataApi = (params) => {
  return axios.request({
    url: 'hallPlayFile/list.do',
    params,
    method: 'get',
  })
}

//远程控制，获取多媒体控制设备列表数据
export const remoteControlDeviceMediaDataApi = (data) => {
  return axios.request({
    url: 'remote_control_device_media_data',
    data,
    method: 'get',
  })
}

//远程控制，获取其他设备数据
export const remoteControlOtherDeviceDataApi = (data) => {
  return axios.request({
    url: 'remote_control_other_device_data',
    data,
    method: 'get',
  })
}

// 设置播放方式
export const setPlayMethod = (params) => {
  return axios.request({
    url: 'hallCtl/setPlayMethod.do',
    params,
    method: 'get',
  })
}
// 调节音量
export const setVoice = (params) => {
  return axios.request({
    url: 'hallCtl/setVoice.do',
    params,
    method: 'get',
  })
}
// 展项开关机
export const optionPower = (params) => {
  return axios.request({
    url: 'hallCtl/optionPower.do',
    params,
    method: 'get',
  })
}
// 一键开关机
export const hallPower = (params) => {
  return axios.request({
    url: 'hallCtl/hallPower.do',
    params,
    method: 'get',
  })
}
// 控制播放
export const ctlPlay = (params) => {
  return axios.request({
    url: 'hallCtl/ctlPlay.do',
    params,
    method: 'get',
  })
}
// 投影仪开关
export const projectorPower = (params) => {
  return axios.request({
    url: 'hallCtl/projectorPower.do',
    params,
    method: 'get',
  })
}
// 更新资源文件
export const editFile = (params) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'hallCtl/editFile.do',
    data:params,
    method: 'post',
  })
}
