<template>
  <div class="data-analyse-page content-page">
    <div class="top-title-box flex al-i-c ju-c-c">
      <div class="zebra-box m-r-12">
        <zebra-marking direction="to-left" :height="14" />
      </div>
      <div class="title-text">{{ currHallName }}</div>
      <div class="zebra-box m-l-12">
        <zebra-marking direction="to-right" :height="14" />
      </div>
    </div>
    <div class="three-box m-b-20 flex ju-c-sb">
      <div class="chunk-item">
        <div class="title-box flex al-i-c ju-c-sb">
          <div class="title-text">资源文件夹统计</div>
          <div class="title-line">
            <zebra-marking :height="14" />
          </div>
        </div>
        <div class="echart-view">
          <vue-echarts :option="chartsOption1" style="height: 100%" />
        </div>
      </div>
      <div class="chunk-item">
        <div class="title-box flex al-i-c ju-c-sb">
          <div class="title-text">设备统计</div>
          <div class="title-line">
            <zebra-marking :height="14" />
          </div>
        </div>
        <div class="echart-view">
          <vue-echarts :option="chartsOption2" style="height: 100%" />
        </div>
      </div>
      <div class="chunk-item">
        <div class="title-box flex al-i-c ju-c-sb">
          <div class="title-text">近10日能耗分析</div>
          <div class="title-line">
            <zebra-marking :height="14" />
          </div>
        </div>
        <block v-if="showEnergy">
          <div class="echart-view">
            <vue-echarts :option="chartsOption3" style="height: 100%" />
          </div>
        </block>
        <div v-else class="text-c no-data-box">
          <div>暂无能耗数据分析</div>
          <img
            class="no-data-img"
            src="../assets/images/worksheet.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="big-zxt-box" v-if="showPassenger">
      <div class="chunk-item">
        <div class="title-box flex al-i-c ju-c-sb">
          <div class="title-text">每日客流数据统计</div>
          <div class="title-line">
            <zebra-marking :height="14" />
          </div>
        </div>
        <div class="echart-view">
          <vue-echarts :option="chartsOption4" style="height: 100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataAnalyseDataApi } from '@/apis/dataAnalysisApis'
import zebraMarking from '@/components/zebra-marking'

export default {
  name: 'DataAnalyse',
  components: {
    zebraMarking,
  },
  data() {
    return {
      currHallName: '',
      chartsOption1: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['文件总数', '展项使用量'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        series: [
          {
            name: '文件总数',
            type: 'bar',
            color: '#f6c26e',
            data: [],
            barWidth: this.$px2spx(8),
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: '展项使用量',
            type: 'bar',
            color: '#1ef2f3',
            data: [],
            barWidth: this.$px2spx(8),
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      },
      chartsOption2: {
        legend: {
          top: 'middle',
          right: this.$px2spx(30),
          orient: 'vertical',
          textStyle: {
            color: '#fff',
          },
        },
        series: [
          {
            type: 'pie',
            radius: [this.$px2spx(40), this.$px2spx(140)],
            center: ['37%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: this.$px2spx(2),
            },
            label: {
              show: true,
              formatter: '{d}%',
              color: '#fff',
            },
            data: [],
          },
        ],
      },
      chartsOption3: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['年度累计完成', '月底完成'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        series: [
          {
            name: '年度累计完成',
            type: 'line',
            color: '#f6c26e',
            data: [],
          },
          {
            name: '月底完成',
            type: 'line',
            color: '#1ef2f3',
            data: [],
          },
        ],
      },
      chartsOption4: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['年度累计完成', '月底完成'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        series: [
          {
            name: '年度累计完成',
            type: 'line',
            color: '#f6c26e',
            data: [120, 150, 306, 410, 210, 320, 210, 340, 120, 410],
            smooth: true,
            lineStyle: {
              width: this.$px2spx(2),
            },
            showSymbol: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(246,194,110,0.6)',
                },
                {
                  offset: 1,
                  color: 'rgba(246,194,110,0.1)',
                },
              ]),
            },
          },
          {
            name: '月底完成',
            type: 'line',
            color: '#1ef2f3',
            data: [90, 200, 320, 300, 440, 200, 310, 230, 190, 320],
            smooth: true,
            lineStyle: {
              width: this.$px2spx(2),
            },
            showSymbol: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(30,242,243,0.5)',
                },
                {
                  offset: 1,
                  color: 'rgba(30,242,243,0.1)',
                },
              ]),
            },
          },
        ],
      },
      showEnergy: true,
      showPassenger: true,
    }
  },
  watch: {
    '$route.params': {
      //监听路由可以通过参数的不同进行判断是否需要重新加载数据
      //用于缓存动态路由页面的时候id发生变化数据未重新请求接口
      deep: true,
      handler() {
        if (this.$route.matched[1].path === '/dataAnalyse/:id/:hallName') {
          this.getData()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.currHallName = this.$route.params.hallName
  },
  methods: {
    async getData() {
      let res = await dataAnalyseDataApi({
        hallId: this.$route.params.id,
      })
      console.log(res)
      let resource = res.data.fileTypeData
      let device = res.data.optStatusData
      let energyConsumption = res.data.electricityData
      let passenger = res.data.guestsData

      let cO1 = {
        data: [],
        series: [[], []],
      }
      //资源文件统计
      resource.forEach((item) => {
        cO1.data.push(item.x)
        cO1.series[0].push(item.y1)
        cO1.series[1].push(item.y2)
      })
      this.chartsOption1.xAxis.data = cO1.data
      cO1.series.forEach((item, index) => {
        this.chartsOption1.series[index].data = item
      })
      //设备统计
      let cO2 = []
      device.forEach((item) => {
        cO2.push({
          name: item.x,
          value: item.y1,
        })
      })
      this.chartsOption2.series[0].data = cO2

      // 能耗分析

      if (energyConsumption.length !== 0) {
        let cO3 = {
          data: [],
          series: [[], []],
        }

        energyConsumption.forEach((item) => {
          cO3.data.push(item.date + '月')
          cO3.series[0].push(item.year)
          cO3.series[1].push(item.month)
        })
        this.chartsOption3.xAxis.data = cO3.data
        cO3.series.forEach((item, index) => {
          this.chartsOption3.series[index].data = item
        })
      } else {
        this.showEnergy = false
      }

      if (passenger.length !== 0) {
        // 客流统计
        let cO4 = {
          data: [],
          series: [[], []],
        }
        passenger.forEach((item) => {
          cO4.data.push(item.date + '月')
          cO4.series[0].push(item.year)
          cO4.series[1].push(item.month)
        })
        this.chartsOption4.xAxis.data = cO4.data
        cO4.series.forEach((item, index) => {
          this.chartsOption4.series[index].data = item
        })
      } else {
        this.showPassenger = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-analyse-page {
  margin: 0 34px;
  padding: 20px 34px;
  border: 1px solid #092a6e;
  box-shadow: 0 0 20px 6px #090f3b inset;

  .top-title-box {
    margin-bottom: 20px;

    .zebra-box {
      width: 200px;
    }

    .title-text {
      color: #1ef2f3;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .chunk-item {
    width: 570px;
    height: 380px;
    padding: 20px;
    position: relative;
    background-image: url('../assets/images/home-chunk-bg.png');
    background-size: 570px 380px;
    background-repeat: no-repeat;
    .no-data-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
      color: #999;
      font-size: 14px;
      height: calc(100% - 60px);
      .no-data-img {
        margin-top: 20px;
        opacity: 0.5;
        width: 150px;
      }
    }

    .title-box {
      height: 45px;
      margin-bottom: 15px;
      overflow: hidden;
      flex-wrap: nowrap;

      .title-text {
        font-size: 22px;
        color: #fff;
        margin-right: 12px;
      }

      .title-line {
        flex: 1;
        /*height: 14px;*/
        /*background-image: url("../assets/images/title-line.png");*/
        /*background-size: 402px 14px;*/
        /*background-repeat: no-repeat;*/
      }
    }

    .echart-view {
      height: calc(100% - 60px);
    }
  }

  .three-box {
    width: 1750px;
    margin: 0 auto;
  }

  .big-zxt-box {
    .chunk-item {
      margin: 0 auto;
      width: 1750px;
      height: 380px;
      background-image: url('../assets/images/big-chunk-bg.png');
      background-size: 1750px 380px;
    }
  }
}
</style>
