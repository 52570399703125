<template>
  <div class="content-page permission-page manage-type-page">
    <div class="flex">
      <div class="menu-tree-box">
        <Menu class="tree-menu">
          <my-menu-item v-for="(item,index) in menuTreeData" :key="index" :item="item" @clickMenu="clickMenu" />
        </Menu>
      </div>
      <div class="edit-menu-box">
        <div class="my-from-box">
          <Form :label-width="$px2spx(100)" :model="editFormModel" label-position="left">
            <FormItem label="上级菜单">
              <Cascader v-model="parentIds" class="my-cascader" :data="cascaderData" change-on-select></Cascader>
            </FormItem>
            <FormItem label="菜单名称">
              <Input v-model="editFormModel.title" placeholder="请输入菜单名称"></Input>
            </FormItem>
            <FormItem label="路由名称">
              <Input v-model="editFormModel.name" placeholder="请输入路由名称"></Input>
            </FormItem>
            <FormItem label="路由路径">
              <Input v-model="editFormModel.jump" placeholder="请输入路由路径"></Input>
            </FormItem>
            <FormItem label="文件路径">
              <Input v-model="editFormModel.memo" type="tel" placeholder="请输入页面文件路径"></Input>
            </FormItem>
            <FormItem label="其他设置">
              <div class="flex">
                <div class="flex al-i-c m-r-15">
                  <span class="m-r-20 main-color">选择展厅</span>
                  <i-switch v-model="editFormModel.showSelect" />
                </div>
                <div class="flex al-i-c m-r-15">
                  <span class="m-r-20 main-color">导航隐藏</span>
                  <i-switch v-model="editFormModel.hidden" />
                </div>
              </div>
            </FormItem>
            <FormItem label="状态">
              <i-switch :true-value="1" :false-value="0" v-model="editFormModel.activeFlag" />
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  getPermissionDataApi,
  addPermissionInfoApi,
  editPermissionInfoApi,
  deletePermissionInfoApi,
} from '@/apis/systemApis'
import { deepCopyObj } from '@/libs/util'
import myMenuItem from '@/components/my-menu-item'
export default {
  name: 'Permission',
  components: {
    myMenuItem,
  },
  data() {
    return {
      menuTreeData: [],
      editFormModel: {
        activeFlag: 1,
        showSelect: false,
        hidden: false,
        memo: '',
      },
      parentIds: [0],
      isSubmiting: false,
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    cascaderData() {
      let list = deepCopyObj(this.menuTreeData)
      list.unshift({
        title: '顶级菜单',
        rowId: 0,
      })
      return list.map((item) => {
        item.label = item.title
        item.value = item.rowId
        item.children = []
        return item
      })
    },
  },
  methods: {
    async getData() {
      let result = await getPermissionDataApi()
      console.log('result:', result)
      this.menuTreeData = result.data
    },
    async clickMenu(e) {
      console.log(e)
      if (e.type === 'add') {
        this.parentIds = [e.item.rowId]
        this.editFormModel = {
          parentId: e.item.rowId,
          activeFlag: 1,
          hidden: false,
        }
      } else if (e.type === 'edit') {
        this.editFormModel = deepCopyObj(e.item)
        if (this.editFormModel.parentId === 0) {
          this.parentIds = [0]
        } else {
          this.parentIds = [this.editFormModel.parentId]
        }
      } else if (e.type === 'del') {
        await this.submitDelete(e.item.rowId)
        e.vm.trashSuccess()
        this.getData()
      }
    },

    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deletePermissionInfoApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 提交新增/修改
    async submitData() {
      if (!this.isSubmiting) {
        this.isSubmiting = true
        this.editFormModel.sysName = this.editFormModel.showSelect ? 'Y' : 'N'
        try {
          if (this.editFormModel.hasOwnProperty('rowId')) {
            //修改
            let editResult = await editPermissionInfoApi(this.editFormModel)
            console.log('editResult:', editResult)
          } else {
            //新增
            this.editFormModel.parentId = this.parentIds[0]
            let addResult = await addPermissionInfoApi(this.editFormModel)
            console.log('addResult:', addResult)
          }
          // this.$refs.myDrawerCom.hide()
          this.getData()
          this.isSubmiting = false
        } catch (error) {
          this.isSubmiting = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.permission-page {
  /deep/.menu-tree-box {
    width: 600px;
    .tree-menu,
    .ivu-menu {
      width: 100% !important;
      background: transparent !important;
      color: #fff !important;
      &::after {
        display: none;
      }
      .ivu-menu-item {
        &.ivu-menu-item-selected {
          background: unset;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .edit-menu-box {
    margin-left: 50px;
    .main-color {
      color: #00eff1;
    }
  }
}
</style>