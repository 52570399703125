<template>
  <div class="video-com">
    <div class="video-box" @click.stop="()=>{}">
      <video style="width: 100%;" v-if="videoUrl!==''" ref="monitoringVideo" class="video-js vjs-default-skin" preload="auto" >
        <source  :src="videoUrl" type="video/x-flv">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'video-com',
  props: {
    width: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      videoUrl: '',
      player: null,
    }
  },

  methods: {
    init(videoUrl) {
      this.player = null // 重置player为null，使下次进入自动播放
      this.videoUrl = videoUrl
      this.$nextTick(() => {
        this.player = this.$videojs(
          this.$refs.monitoringVideo,
          {
            autoplay: true,
            controls: true,
            muted: false, //静音模式, 解决首次页面加载播放。
            techOrder: ['html5', 'flvjs'], // 兼容顺序
            flvjs: {
              mediaDataSource: {
                isLive: false,
                cors: true,
                withCredentials: false,
              },
            },
          },
          function () {
            this.play() // 自动播放
          }
        )
      })
    },
    delVideo() {
      if (this.player != null) {
        setTimeout(() => {
          //this.player.dispose() // dispose()会直接删除Dom元素
          this.videoUrl = ''
        }, 420)
      }
    },
    play() {
      this.player.play()
    },
    pause() {
      this.player.pause()
    },
  },
}
</script>

<style lang="scss" scoped>
.video-com {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  .video-box {
    width: 100%;
    height: 100%;

    .video-js {
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
}
</style>
