import axios from '@/libs/api.request'
import { getToken } from '@/libs/util'

import config from '@/config'

let baseUrl = config.baseUrl[process.env.VUE_APP_NAME]

// 获取文件列表数据
export const getFileDataApi = (data) => {
  return axios.request({
    url: 'hallFile/list.do',
    data,
    method: 'post',
  })
}

// 新增文件
export const addFileApi = (data) => {
  return axios.request({
    url: 'hallFile/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改文件
export const editFileApi = (data) => {
  return axios.request({
    url: 'hallFile/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除文件
export const deleteFileApi = (data) => {
  return axios.request({
    url: 'hallFile/delete.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 上传文件
export const uploadFileApi = baseUrl + 'upload/file.do'

// 海报新增
export const addDiyPosterApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'diyImg/add.do',
    data,
    method: 'post',
  })
}

// 海报列表
export const getDiyPosterDataApi = (data) => {
  return axios.request({
    url: 'diyImg/list.do',
    data,
    method: 'post',
  })
}

// 修改海报
export const editDiyPosterApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'diyImg/edit.do',
    data,
    method: 'post',
  })
}

// 删除海报
export const deleteDiyPosterApi = (data) => {
  return axios.request({
    url: 'diyImg/delete.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
