<template>
  <div class="content-page poster-management-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="fileName">
            <Input type="text" v-model="searchModel.posterName" placeholder="请输入查询海报名称"></Input>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line-text'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已失效' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="previewRow(row)">
              <Icon type="ios-eye" />
              预览
            </div>
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
    <my-drawer-com :title="(posterInfo.hasOwnProperty('rowId')?'编辑':'新增')+'欢迎词'" ref="myDrawerCom" :width="100">
      <template slot="body">
        <div class="poster-title">欢迎词设置</div>
        <Form inline ref="posterInfo" :model="posterInfo" :rules="posterInfoRule">
          <FormItem prop="posterName" label="欢迎词名称">
            <Input class="poster-input" type="text" v-model="posterInfo.posterName" placeholder="请输入欢迎词名称"></Input>
          </FormItem>
          <FormItem prop="width" label="欢迎词宽度">
            <Input class="poster-input" type="number" v-model.number="posterInfo.width" @on-blur="resetWH" placeholder="请输入欢迎词宽度"></Input>
          </FormItem>
          <FormItem prop="height" label="欢迎词高度">
            <Input class="poster-input" type="number" v-model.number="posterInfo.height" @on-blur="resetWH" placeholder="请输入欢迎词高度"></Input>
          </FormItem>
          <FormItem prop="hallId" label="所属展厅">
            <Select class="poster-input" filterable placeholder="请输入展厅名称搜索" v-model="posterInfo.hallId" :remote-method="remoteMethod" :loading="loading">
              <Option v-for="(option, index) in hallList" :value="option.value" :key="index">{{option.label}}</Option>
            </Select>
          </FormItem>
          <FormItem prop="memo" label="欢迎词备注">
            <Input class="poster-input" type="text" v-model="posterInfo.memo" placeholder="请输入备注"></Input>
          </FormItem>
          <FormItem label="操作">
            <Button class="poster-btn" @click="savePoster">提交保存</Button>
          </FormItem>
        </Form>
        <div class="poster-content-box">
          <div class="poster-all-box flex">
            <div class="poster-edit-scorll custom-scrollbar m-r-30">
              <div class="poster-edit-wrap">
                <div class="poster-edit-box" v-if="isShowPoster" :style="{width:posterInfo.width+'px',height:posterInfo.height+'px'}">
                  <vue-draggable-resizable v-for="(item,index) in posterInfo.elements" :style="item.key==='bgimg'?{zIndex:1}:{zIndex:2}" :parent="true" :key="index" :w="item.w" :h="item.h" :x="item.x" :y="item.y" @dragstop="(x,y)=>onDragstop(x,y,index)" @resizestop="(x, y, width, height)=>onResizstop(x, y, width, height,index)" @deactivated="onDeactivated(index)" @activated="onActivated(index)">
                    <div class="element-box" v-if="item.key==='text'" :style="item.style">{{item.text}}</div>
                    <div class="element-box" v-if="item.key==='bgimg'">
                      <img v-if="item.url!==''" :src="item.url" :style="{width:'100%'}" alt="">
                    </div>
                    <div class="delete-item-icon iconfont icon-guanbi" @click="deleteItem(index)"></div>
                  </vue-draggable-resizable>
                </div>
              </div>
            </div>
            <div class="setting-box" v-if="isShowComSetting">
              <h3 class="title m-b-20">参数设置</h3>
              <div class="upload-img" v-if="posterInfo.elements[currComIndex].key==='bgimg'">
                <Upload class="my-upload" type="drag" accept=".pdf,image/*,audio/*,video/*" :on-remove="fileRemove" :on-success="fileUploadSuccess" :headers="uploadFileHeaders" :action="uploadFileApi">
                  <div style="padding: 20px 0">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p>点击或拖动文件到此处进行上传</p>
                  </div>
                </Upload>
                <Form :label-width="70">
                  <FormItem label="图片宽度">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].w" placeholder="请输入图片宽度"></Input>
                  </FormItem>
                  <FormItem label="图片高度">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].h" placeholder="请输入图片高度"></Input>
                  </FormItem>
                  <FormItem label="横坐标">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].x" placeholder="请输入图片横坐标"></Input>
                  </FormItem>
                  <FormItem label="纵坐标">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].y" placeholder="请输入图片纵坐标"></Input>
                  </FormItem>
                </Form>
              </div>
              <div class="text-box" v-if="posterInfo.elements[currComIndex].key==='text'">
                <Form :label-width="70">
                  <FormItem label="文本内容">
                    <Input class="poster-input" type="text" v-model="posterInfo.elements[currComIndex].text" placeholder="请输入文本内容"></Input>
                  </FormItem>
                  <FormItem label="横坐标">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].x" placeholder="请输入文本横坐标"></Input>
                  </FormItem>
                  <FormItem label="纵坐标">
                    <Input class="poster-input" type="number" v-model.number="posterInfo.elements[currComIndex].y" placeholder="请输入文本纵坐标"></Input>
                  </FormItem>
                  <FormItem label="字体大小">
                    <Input class="poster-input" type="text" v-model="posterInfo.elements[currComIndex].style.fontSize" @on-blur="resetLh" placeholder="请输入字体大小"></Input>
                  </FormItem>
                  <FormItem label="文本行高">
                    <Input class="poster-input" type="text" v-model="posterInfo.elements[currComIndex].style.lineHeight" placeholder="请输入文本行高"></Input>
                  </FormItem>
                  <FormItem label="字体颜色">
                    <ColorPicker v-model="posterInfo.elements[currComIndex].style.color" format="rgb" />
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
          <div class="com-list m-t-20">
            <Button class="poster-btn m-r-10" @click="addElement('bgimg')">背景图</Button>
            <Button class="poster-btn" @click="addElement('text')">文本</Button>
          </div>
        </div>
      </template>
    </my-drawer-com>
    <Modal v-model="showPreview" title="预览" :footer-hide="true" fullscreen>
      <img style="display:block;margin: 0 auto;max-width:100%;" :src="currPreviewImg" alt="">
    </Modal>
  </div>
</template>

<script>
import { getToken } from '@/libs/util'
import {
  getDiyPosterDataApi,
  addDiyPosterApi,
  editDiyPosterApi,
  deleteDiyPosterApi,
  uploadFileApi,
} from '@/apis/fileManageApis'
import { hallInfoDataApi } from '@/apis/exhibitionManagementApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
export default {
  name: 'PosterManagement',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    return {
      uploadFileApi,
      uploadFileHeaders: {
        tokenId: getToken(),
      },
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '欢迎词名称',
          key: 'posterName',
          align: 'center',
        },
        {
          title: '文件地址',
          key: 'fileUrl',
          align: 'center',
        },
        {
          title: '文件大小',
          key: 'fileSize',
          align: 'center',
        },
        {
          title: '备注',
          key: 'memo',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      isShowPoster: true,
      pageSize: 10,
      currPage: 1,
      total: 0,
      isSubmiting: false,
      posterInfo: {
        posterName: '',
        width: 1920,
        height: 1080,
        elements: [],
        memo: '',
      },
      posterInfoRule: {
        posterName: [
          {
            required: true,
            message: '请输入欢迎词名称',
            trigger: 'blur',
          },
        ],
        width: [
          {
            required: true,
            message: '请输入欢迎词宽度',
            trigger: 'blur',
            type: 'number',
          },
        ],
        height: [
          {
            required: true,
            message: '请输入欢迎词宽度',
            trigger: 'blur',
            type: 'number',
          },
        ],
        hallId: [
          {
            required: true,
            message: '请选择欢迎词所属展厅',
            trigger: 'blur',
            type: 'number',
          },
        ],
        memo: [
          {
            required: false,
            message: '请输入欢迎词备注',
            trigger: 'blur',
          },
        ],
      },
      currComIndex: -1,
      isShowComSetting: false,
      selectItems: [],
      searchModel: {},
      showPreview: false,
      currPreviewImg: '',
      ssT: null,
      loading: false,
      hallList: [],
    }
  },
  async mounted() {
    this.getData()
  },
  methods: {
    resetWH() {
      this.isShowPoster = false
      setTimeout(() => {
        this.isShowPoster = true
      }, 10)
      console.log(this.posterInfo)
    },
    // 判断文字行高是否小于字体大小
    resetLh() {
      let fz = parseInt(
        this.posterInfo.elements[this.currComIndex].style.fontSize
      )
      let lh = parseInt(
        this.posterInfo.elements[this.currComIndex].style.lineHeight
      )
      if (lh < fz) {
        this.posterInfo.elements[this.currComIndex].style.lineHeight =
          this.posterInfo.elements[this.currComIndex].style.fontSize
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        if (!this.ssT) {
          this.ssT = setTimeout(async () => {
            this.loading = true
            let res = await hallInfoDataApi({
              hallName: query,
            })
            console.log(res)
            this.loading = false
            this.hallList = res.data.map((item) => {
              item.value = item.rowId
              item.label = item.orgName
              return item
            })
            this.ssT = null
          }, 400)
        } else {
          clearTimeout(this.ssT)
          this.ssT = null
        }
      } else {
        this.hallList = []
      }
    },
    // 删除该元素
    deleteItem(index) {
      this.$Modal.confirm({
        title: '确认要删除吗？',
        onOk: () => {
          this.posterInfo.elements.splice(index, 1)
          this.isShowComSetting = false
          this.currComIndex = -1
        },
      })
    },
    // 添加元素
    addElement(key) {
      let obj = {}
      if (key === 'bgimg') {
        obj = {
          key: 'bgimg',
          url: '',
          w: 100,
          h: 100,
          x: 100,
          y: 100,
        }
      } else if (key === 'text') {
        obj = {
          key: 'text',
          text: '',
          w: 100,
          h: 30,
          x: 100,
          y: 100,
          style: {
            fontSize: '12px',
            lineHeight: '12px',
            color: 'rgba(255,255,255,1)',
          },
        }
      }
      this.posterInfo.elements.push(obj)
    },
    // 拖动时
    onDragstop(x, y, index) {
      let item = this.posterInfo.elements[index]
      item.x = x
      item.y = y
      this.posterInfo.elements.splice(index, 1, item)
    },
    // 改变大小时
    onResizstop(x, y, width, height, index) {
      let item = this.posterInfo.elements[index]
      item.x = x
      item.y = y
      item.w = width
      item.h = height
      this.posterInfo.elements.splice(index, 1, item)
    },
    // 点击组件外时
    onDeactivated(index) {
      console.log(index)
      // this.showComSetting(false)
    },
    // 点击组件时
    onActivated(index) {
      console.log(index)
      this.currComIndex = index
      this.showComSetting(true)
    },
    // 显示与隐藏组件参数设置
    showComSetting(isShow) {
      this.isShowComSetting = isShow
    },
    savePoster() {
      console.log(this.posterInfo)
      this.$refs['posterInfo'].validate(async (valid) => {
        console.info(valid)
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              if (this.posterInfo.hasOwnProperty('rowId')) {
                //修改
                let editResult = await editDiyPosterApi(this.posterInfo)
                console.log('editResult:', editResult)
              } else {
                //新增
                let addResult = await addDiyPosterApi(this.posterInfo)
                console.log('addResult:', addResult)
              }
              this.posterInfo = {
                posterName: '',
                width: 1920,
                height: 1080,
                memo: '',
                elements: [],
              }
              this.isShowComSetting = false
              this.currComIndex = -1
              this.getData()
              this.isSubmiting = false
              this.$refs.myDrawerCom.hide()
            } catch (error) {
              console.log(error)
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },

    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      console.log('search:', search)
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await getDiyPosterDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data.map((item) => {
          item.width = Number(item.width)
          item.height = Number(item.height)
          item.elements = JSON.parse(item.elements)
          return item
        })
        this.total = res.count
      } catch (error) {}
    },
    // 新增行
    addRow() {
      this.posterInfo = {
        posterName: '',
        width: 1920,
        height: 1080,
        memo: '',
        elements: [],
      }
      this.$refs.myDrawerCom.show()
    },
    // 预览
    previewRow(row) {
      this.currPreviewImg = row.fileUrl
      this.showPreview = true
    },
    // 编辑行
    async editRow(row) {
      this.posterInfo = deepCopyObj(row)
      console.log(this.posterInfo)
      let res = await hallInfoDataApi({
        hallName: this.posterInfo.hallName,
      })
      this.hallList = res.data.map((item) => {
        item.value = item.rowId
        item.label = item.orgName
        return item
      })
      this.$refs.myDrawerCom.show()
    },
    // 删除行
    async trashRow(row, vm) {
      console.log(row)
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteDiyPosterApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 文件上传成功
    fileUploadSuccess(res, file) {
      console.log(res, file)
      this.posterInfo.elements[this.currComIndex].url = res.data.url

      let nImg = new Image()
      nImg.onload = () => {
        let w = nImg.width
        let h = nImg.height
        this.posterInfo.elements[this.currComIndex].w = w
        this.posterInfo.elements[this.currComIndex].h = h
      }
      nImg.src = res.data.url
    },
    fileRemove() {
      this.posterInfo.elements[this.currComIndex].url = ''
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.poster-input {
  .ivu-input,
  .ivu-select-selection {
    background-color: transparent;
    border-color: #1a7aca;
    color: #fff;
  }
  .ivu-select-input {
    color: #fff;
  }
}
/deep/.poster-btn {
  background-color: #166bb3;
  border-color: #166bb3;
  color: #fff;
}
.poster-title {
  font-size: 18px;
}

.poster-content-box {
  height: calc(100% - 120px);
  .poster-all-box {
    height: calc(100% - 50px);
  }

  .poster-edit-scorll {
    width: 100%;
    height: 100%;
    overflow: auto;
    border: 1px solid #092a6e;
    box-shadow: 0 0 20px 6px #090f3b inset;
    .poster-edit-wrap {
      display: inline-flex;
      padding: 15px;
      .poster-edit-box {
        border: 1px solid rgba($color: #00eff1, $alpha: 0.2);
        position: relative;
        .vdr {
          border-color: #00eff1;
          cursor: move;
        }
        .element-box {
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
        }
        .delete-item-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #00eff1;
          color: #fff;
          width: 15px;
          height: 15px;
          line-height: 15px;
          font-size: 12px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .setting-box {
    width: 20%;
    border: 1px solid #092a6e;
    box-shadow: 0 0 20px 6px #090f3b inset;
    padding: 15px;
    position: relative;
    .title {
      font-size: 16px;
    }
  }
}
</style>
