<template>
  <div class="content-page admin-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex al-i-c">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <div class="add-btn" @click="addRow">新增</div>
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="userName">
            <Input type="text" v-model="searchModel.userName" placeholder="请输入查询用户名称"></Input>
          </FormItem>
          <FormItem prop="roleId">
            <Select v-model="searchModel.roleId" clearable placeholder="请选择查询用户角色" transfer transfer-class-name="my-select">
              <Option v-for="(item,index) in roleList" :value="item.rowId" :key="index">{{ item.roleName }}</Option>
            </Select>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table :height="$px2spx(500)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="state">
          <div :class="row.activeFlag?'on-line-text':'off-line'">
            <Icon :type="row.activeFlag?'ios-checkmark-circle-outline':'ios-close-circle-outline'" />
            <span class="m-l-5">{{ row.activeFlag?'使用中':'已禁用' }}</span>
          </div>
        </template>
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editRow(row)">
              <Icon type="md-create" />
              修改
            </div>
            <trash-affirm-com @confirm="(vm)=>trashRow(row,vm)">
              <div slot="body" class="cur-po off-line-text">
                <Icon type="md-trash" />
                删除
              </div>
            </trash-affirm-com>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="getData" show-elevator />
    </div>
    <my-drawer-com :title="(editFormModel.hasOwnProperty('rowId')?'编辑':'新增')+'用户'" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editFormModel" :model="editFormModel" :rules="editFormModelRule" :label-width="$px2spx(100)" label-position="left">
            <FormItem label="用户账号" prop="userName">
              <Input v-model="editFormModel.userName" placeholder="请输入用户账号"></Input>
            </FormItem>
            <FormItem label="用户密码" prop="password" v-if="!editFormModel.hasOwnProperty('rowId')">
              <Input type="text" v-model="editFormModel.password" placeholder="请输入用户密码"></Input>
            </FormItem>
            <FormItem label="用户类型" prop="userType">
              <Select v-model="editFormModel.userType" placeholder="请选择用户类型">
                <Option v-for="item in userTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem v-if="['hall','area','hallOpt'].indexOf(editFormModel.userType)!==-1" label="所属展厅" prop="orgIds">
              <Select transfer-class-name="my-select" v-model="editFormModel.orgIds" placeholder="请选择展厅" @on-change="getHallOptList" :multiple="editFormModel.userType==='area'">
                <Option v-for="(item,index) in hallList" :value="item.rowId" :key="index">{{ item.hallName }}</Option>
              </Select>
            </FormItem>
            <FormItem v-if="['hallOpt'].indexOf(editFormModel.userType)!==-1&&optList.length>0" label="选择展项" prop="orgIds">
              <Select transfer-class-name="my-select" v-model="editFormModel.optIds" placeholder="请选择展项" :multiple="true">
                <Option v-for="(item,index) in optList" :value="item.rowId" :key="index">{{ item.optionName }}</Option>
              </Select>
            </FormItem>
            <!-- <FormItem label="角色类型" prop="roleId">
              <Select v-model="editFormModel.roleId" placeholder="请选择角色类型" transfer transfer-class-name="my-select">
                <Option v-for="item in roleList" :value="item.rowId" :key="item.rowId">{{ item.roleName }}</Option>
              </Select>
            </FormItem> -->
            <FormItem label="电话号码" prop="phone">
              <Input v-model="editFormModel.phone" type="tel" placeholder="请输入手机号码"></Input>
            </FormItem>
            <FormItem label="状态">
              <i-switch :true-value="1" :false-value="0" v-model="editFormModel.activeFlag" />
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitData">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
import {
  getUserDataApi,
  addUserInfoApi,
  editUserInfoApi,
  deleteUserInfoApi,
  getRoleDataApi,
} from '@/apis/systemApis'
import {
  hallInfoDataApi,
  hallOptionDataApi,
} from '@/apis/exhibitionManagementApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
export default {
  name: 'Admin',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }

    const validateOrgIds = (rule, value, callback) => {
      if (
        this.editFormModel.userType === 'hall' ||
        this.editFormModel.userType === 'hallOpt'
      ) {
        if (typeof value === 'number') {
          callback()
        } else {
          callback(new Error('请选择展厅'))
        }
      } else if (this.editFormModel.userType === 'area') {
        if (Array.isArray(value) && value.length > 0) {
          callback()
        } else {
          callback(new Error('请选择展厅'))
        }
      } else {
        console.log('ooo')
      }
    }
    const validateOptIds = (rule, value, callback) => {
      if (this.editFormModel.userType === 'hallOpt') {
        if (Array.isArray(value) && value.length > 0) {
          callback()
        } else {
          callback(new Error('请选择展项'))
        }
      } else {
        console.log('xxx')
      }
    }

    return {
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '账号',
          key: 'userName',
          align: 'center',
        },
        {
          title: '用户类型',
          key: 'userTypeName',
          align: 'center',
        },
        {
          title: '角色类型',
          key: 'roleName',
          align: 'center',
        },
        {
          title: '电话号码',
          key: 'phone',
          align: 'center',
        },
        {
          title: '当前状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      userTypeList: [
        {
          value: 'system',
          label: '平台用户',
        },
        {
          value: 'area',
          label: '区域管理',
        },
        {
          value: 'hall',
          label: '展厅管理员',
        },
        {
          value: 'hallOpt',
          label: '展厅操作员',
        },
      ],
      pageSize: 10,
      currPage: 1,
      total: 0,
      roleList: [],
      hallList: [],
      optList: [],
      isSubmiting: false,
      editFormModel: {},
      editFormModelRule: {
        userName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          },
        ],
        userType: [
          {
            required: true,
            message: '请选择用户类型',
            trigger: 'change',
          },
        ],
        orgIds: [
          {
            required: true,
            message: '请选择展厅',
            validator: validateOrgIds,
            trigger: 'change',
          },
        ],
        optIds: [
          {
            required: true,
            message: '请选择展项',
            validator: validateOptIds,
            trigger: 'change',
          },
        ],
        roleId: [
          {
            type: 'number',
            required: true,
            message: '请选择角色类型',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
      },
      searchModel: {},
      selectItems: [],
    }
  },
  async mounted() {
    this.getData()
    try {
      let roleResult = await getRoleDataApi()
      console.log(roleResult)
      this.roleList = roleResult.data
      let hallResult = await hallInfoDataApi({
        rows: 999,
        currentPage: 1,
      })
      this.hallList = hallResult.data
    } catch (error) {}
  },
  methods: {
    async getData(search = 0) {
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await getUserDataApi(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
    async getHallOptList() {
      this.editFormModel.optIds = []
      try {
        let res = await hallOptionDataApi({
          orgIds: this.editFormModel.orgIds,
          currentPage: 1,
          rows: 999,
        })
        console.log('optList:', res)
        this.optList = res.data
      } catch (error) {}
    },
    addRow() {
      this.editFormModel = {
        activeFlag: 1,
      }
      this.$refs.myDrawerCom.show()
    },
    editRow(row) {
      console.log(row)
      this.editFormModel = deepCopyObj(row)
      this.editFormModel.orgIds = []
      row.orgIds.split(',').forEach((str) => {
        this.editFormModel.orgIds.push(parseInt(str))
      })
      this.$refs.myDrawerCom.show()
    },
    // 删除行
    async trashRow(row, vm) {
      console.log(row)
      await this.submitDelete(row.rowId)
      vm.trashSuccess()
      this.getData()
    },
    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteUserInfoApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    // 提交新增/修改
    submitData() {
      this.$refs['editFormModel'].validate(async (valid) => {
        if (valid) {
          if (!this.isSubmiting) {
            this.isSubmiting = true
            try {
              let postData = {
                orgName: '',
              }
              if (
                this.editFormModel.userType === 'hall' ||
                this.editFormModel.userType === 'hallOpt'
              ) {
                postData.orgName = this.hallList.find(
                  (item) => item.rowId === this.editFormModel.orgIds
                ).hallName

                if (this.editFormModel.userType === 'hallOpt') {
                  postData.optIds = this.editFormModel.optIds.join()
                }
              } else if (this.editFormModel.userType === 'area') {
                postData.orgName = this.hallList
                  .filter((item) =>
                    this.editFormModel.orgIds.includes(item.rowId)
                  )
                  .map((item) => {
                    return item.hallName
                  })
                  .join()

                postData.orgIds = this.editFormModel.orgIds.join()
              }

              if (this.editFormModel.hasOwnProperty('rowId')) {
                //修改
                let editResult = await editUserInfoApi(
                  Object.assign(this.editFormModel, postData)
                )
                console.log('editResult:', editResult)
              } else {
                //新增
                let addResult = await addUserInfoApi(
                  Object.assign(this.editFormModel, postData)
                )
                console.log('addResult:', addResult)
              }
              this.$refs.myDrawerCom.hide()
              this.editFormModel = {
                activeFlag: 1,
              }
              this.getData()
              this.isSubmiting = false
            } catch (error) {
              console.log(error)
              this.isSubmiting = false
            }
          }
        } else {
          this.$Message.error('请输入正确的内容!')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
