<template>
    <div class="home2-page">
        <div id="mapChart" ref="mapChart"></div>
    </div>
</template>

<script>

    import {
        hallInfoDataApi,
    } from '@/apis/exhibitionManagementApis'
    import '@/libs/china.js'
    export default {
        name: "Home2",
        methods: {
            mapFn() {
                // 基于准备好的dom，初始化echarts实例
                var mapChart = this.$echarts.init(this.$refs.mapChart);
                mapChart.setOption({
                    //是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
                    visualMap: {
                        show:false,
                    },
                    geo: {
                        show: true,
                        map: 'china',
                        roam: false,
                        itemStyle: {
                            normal: {
                                borderWidth: 4, //设置外层边框
                                borderColor:'white',
                                shadowColor: '#357bff',
                                shadowBlur: 20
                            },
                        }
                    },
                    // 提示框，鼠标移入
                    tooltip: {
                        show: true, //鼠标移入是否触发数据
                        trigger: "item", //出发方式
                        extraCssText:"background-color: #03102b;color: white; box-shadow:0px 0 5px #23BFF9 inset" ,
                        formatter:  function(params,ticket, callback){
                            let str='<div>' +
                                        '<p style="font-weight: bold;border-bottom: #0c356c 1px solid">'+params.name+'省</p>' +
                                        '<div class="tipShow" ></div>' +
                                    '</div>';
                            return str
                        }
                    },
                    //配置地图的数据，并且显示
                    series: [
                        {
                            name: "地图",
                            type: "map",
                            map: "china", //地图类型。
                            data: [],
                            itemStyle: {
                                areaColor:'#001c52',
                                borderWidth: 1,
                                borderColor: "#00359a",
                                //地图区域的多边形 图形样式。
                                emphasis: {
                                    areaColor: "#4b89ff", //选中状态的地图板块颜色
                                },
                            },
                            zoom: 1, //放大比例
                            label: {
                                //图形上的文本标签，可用于说明图形的一些数据信息
                                show: true,
                                color:"white"
                            },
                        }
                    ],
                }),
                mapChart.getZr().on('click', function (param) {
                    console.info(param)

                }),
                // 鼠标事件
                mapChart.on("click", async function (res) {
                    console.info(res)
                    var tipShow=document.getElementsByClassName('tipShow')[0];
                    let rst = await hallInfoDataApi({"hallCity":res.name});
                    let rt = '<ul style="padding: 20px 0px 10px;font-size: 12px">'
                    if(rst.data && rst.data.length>0 ){
                        let str="";
                        for (var i=0;i<rst.data.length;i++){
                            if(i%2==0){
                                str += '<li style="text-align: center;width: 160px;padding: 1px">' +
                                    '<div style="width: 140px;margin: 0 auto;line-height: 25px;background-color: #0c356c;border-radius: 5px;opacity: 0.7">'
                                    +rst.data[i].hallName+'</div></li>'
                            }else{
                                str += '<li style="text-align: center;width: 160px;line-height: 25px;padding: 1px">'+rst.data[i].hallName+'</li>'
                            }
                        }
                        tipShow.innerHTML = rt + str + "</ul>";
                    }else{
                        tipShow.innerHTML = rt + "<li style='width: 160px;text-align: center;line-height: 25px'>无</li></ul>"
                    }
                })

                mapChart.dispatchAction({
                    type: 'geoToggleSelect',
                })
                window.addEventListener("resize", () => {
                    // 自动渲染echarts
                    mapChart && mapChart.resize();
                });
            },
        },
        mounted() {
            this.mapFn();


        },
    };
</script>
<style lang="scss" scoped>
.home2-page {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/homebg.jpg');
    /*background-image: url('../assets/images/homebg.jpg');*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /*background-position: bottom left;*/
}
#mapChart {
    width: 100%;
    height: calc(100vh - 60px);
}
.zt_pannel{
    margin-top: 30px;
    width: 300px;
    height: 400px;
    opacity:0.6;
    background-color: #03171f;
    border: #03171f 1px solid;
    box-shadow:0px 0 10px #23BFF9 inset;
}
.zt_title{
    padding-left: 60px;
    color: white;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    width: 100%;
    background-image: url('../assets/images/headbg.png');
    background-size: 100%;
    background-repeat: no-repeat;
}
.zt_body{
    text-align: center;
    color: white;
}
</style>
