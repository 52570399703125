<template>
  <div class="content-page bizlog-page manage-type-page">
    <div class="table-box m-b-20">
      <Table :height="$px2spx(500)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe />
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="getData" show-elevator />
    </div>
  </div>
</template>

<script>
import { getBizLogDataApi } from '@/apis/systemApis'
export default {
  name: 'BizLog',
  data() {
    return {
      tableColumns: [
        {
          title: '请求人',
          key: 'reqName',
          align: 'center',
        },
        {
          title: '请求参数',
          key: 'reqParam',
          align: 'center',
        },
        {
          title: '请求类型',
          key: 'reqTypeName',
          align: 'center',
        },
        {
          title: '请求地址',
          key: 'reqUrl',
          align: 'center',
        },
        {
          title: '备注',
          key: 'memo',
          align: 'center',
        },
        {
          title: '请求时间',
          key: 'createdDate',
          align: 'center',
        },
      ],
      tableData: [],
      pageSize: 10,
      currPage: 1,
      total: 0,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        let res = await getBizLogDataApi({
          rows: this.pageSize,
          currentPage: this.currPage,
        })
        console.log('res:', res)
        this.tableData = res.data
        this.total = res.count
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss" scoped>
</style>