<!--实时播放-->
<template>
  <div class="real-time-playing-page content-page">
    <div class="top-title-box flex al-i-c ju-c-c">
      <div class="zebra-box m-r-12">
        <zebra-marking direction="to-left" :height="14" />
      </div>
      <div class="title-text">{{currHallName}}</div>
      <div class="zebra-box m-l-12">
        <zebra-marking direction="to-right" :height="14" />
      </div>
    </div>
    <div class="video-list-box flex al-i-c ju-c-sb">
      <div class="video-item" v-for="(item,index) in videoList" :key="index">
        <div class="media-preview flex al-i-c ju-c-c" @click="getPlayUrl(item,index)">
          <video-com :data-ref="'videoCom'+index" :ref="'videoCom'+index" />
          <template v-if="item.streamStatus===0">
            <img class="video-p" src="../assets/images/temp/video-temp.png" alt="">
            <div class="play-btn cur-po anim iconfont icon-bofang"></div>
          </template>
        </div>
        <div class="video-info">
          <div class="info">设备名称：<span class="label">{{item.optionName}}</span></div>
        </div>
      </div>
    </div>
    <!-- <video-com ref="videoCom" /> -->
  </div>
</template>

<script>
import { hallOptionDataApi } from '@/apis/exhibitionManagementApis'
import { getStreamApi } from '@/apis/remoteControlApis'

import zebraMarking from '@/components/zebra-marking'
import videoCom from '@/components/video-com'

export default {
  name: 'RealTimePlaying',
  components: {
    zebraMarking,
    videoCom,
  },
  data() {
    return {
      currHallName: '',
      videoList: [],
    }
  },
  watch: {
    '$route.params': {
      //监听路由可以通过参数的不同进行判断是否需要重新加载数据
      //用于缓存动态路由页面的时候id发生变化数据未重新请求接口
      deep: true,
      handler() {
        if (this.$route.matched[1].path === '/realTimePlaying/:id/:hallName') {
          this.getData()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.currHallName = this.$route.params.hallName
  },
  methods: {
    async getData() {
      let res = await hallOptionDataApi({
        hallId: this.$route.params.id,
      })
      console.log(res)
      res.data.forEach((item) => {
        item.streamStatus = 0
      })
      this.videoList = res.data
    },
    async getPlayUrl(item, index) {
      console.log(item)
      if (item.streamStatus === 0) {
        item.streamStatus = 1
        try {
          let streamRes = await getStreamApi({
            optionId: item.rowId,
            ctlType: 1,
          })
          console.log(streamRes)
          this.playVideo(index, streamRes.data)
        } catch (error) {
          console.log(error)
          item.streamStatus = 0
        }
      }
    },
    playVideo(index, url) {
      console.log(url, index)
      this.$refs['videoCom' + index][0].init(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.real-time-playing-page {
  margin: 0 34px;
  padding: 20px 40px;
  border: 1px solid #092a6e;
  box-shadow: 0 0 20px 6px #090f3b inset;

  .top-title-box {
    margin-bottom: 20px;

    .zebra-box {
      width: 200px;
    }

    .title-text {
      color: #1ef2f3;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .video-list-box {
    flex-wrap: wrap;

    .video-item {
      width: 560px;
      height: 340px;
      margin-bottom: 20px;

      .media-preview {
        width: 560px;
        height: 300px;
        position: relative;

        .video-p {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 10;
        }

        .play-btn {
          position: absolute;
          font-size: 100px;
          z-index: 100;
          color: #1ef2f3;
        }
      }

      .video-info {
        margin-top: 10px;
        font-size: 14px;
        color: #fff;

        .info::first-letter {
          margin-bottom: 6px;
        }

        .label {
          color: #1ef2f3;
        }
      }
    }
  }
}
</style>
