import axios from '@/libs/api.request'

// 获取主题
export const listThemes = (data) => {
  return axios.request({
    url: 'themes/listThemes.do',
    data,
    method: 'post',
  })
}
// 获取展项
export const listOption = (data) => {
  return axios.request({
    url: 'themes/listOption.do',
    data,
    method: 'post',
  })
}

// 获取文件
export const listFile = (data) => {
  return axios.request({
    url: 'themes/listFile.do',
    data,
    method: 'post',
  })
}

// 删除文件
export const deleteThemesApi = (data) => {
  return axios.request({
    url: 'themes/delete.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
// 生成主题
export const creatThemesApi = (data) => {
  return axios.request({
    url: 'themes/creat.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
// 选择主题
export const selectThemes = (data) => {
  return axios.request({
    url: 'themes/selectThemes.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
